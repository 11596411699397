import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { productsShop, productPrice, productAddStock, productRmvStock } from '../../services/ProductService';
import { getProfile } from '../../services/UserService'
import SwiperCTN  from '../layouts/SwiperCTN'
import { Label } from 'recharts';


const Menu = (props) => {
    const [loading, setLoading] = useState(true);
    const [shopper, setShopper] = useState({});
    const [products, setProducts] = useState([])
    const [toggle, setToggle] = useState(true)
    const [orderTaken, setOrderTaken] = useState({});
    const [modalPrice, setModalPrice] = useState(false);
    const [modalStock, setModalStock] = useState(false);
    const [precoCusto, setPrecoCusto] = useState(0);
    const [precoVenda, setPrecoVenda] = useState(0);
    const [vitrine, setVitrine] = useState(0);
    const [estoque, setEstoque] = useState(0);
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 700);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 700);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleIncrement = (setter) => () => {
        setter(prev => prev + 0.5);
    };

    const handleDecrement = (setter) => () => {
        setter(prev => prev - 0.5);
    };

    const handleStockIncrement = () => {
        setEstoque(prev => prev + 1);
    };

    const handleStockDecrement = () => {
        setEstoque(prev => (prev > 0 ? prev - 1 : 0));
    };

    // const changePage = () => {

    // }

    const setModal = (modal, type, content) => {
        if (type === 'stock') {
            setModalStock(modal)
        } else if (type === 'price') {
            setModalPrice(modal)
        }
        if(content.name) {
            setOrderTaken(content)
            setPrecoCusto(content.costPrice)
            setPrecoVenda(content.salePrice)
            setVitrine(content.onSalePrice)
            setEstoque(0)
        }
    }

    const handlerStore = (id, type) => {
        if (type === 'price') {
            const confirmOrderFunc = async () => {
                try {
                    const fetchConfirm = async () => {
                        try {
                            const response = await productPrice(id, precoCusto , precoVenda , vitrine);
                            if (response.status === 200) {
                                setToggle(true)
                                setPrecoCusto(0)
                                setPrecoVenda(0)
                                setVitrine(0)
                            } else {
                                console.error('Erro ao obter os produtos:', response.statusText);
                            }
                        } catch (error) {
                            console.error('Erro ao obter os produtos:', error);
                        }
                    };
                    fetchConfirm();
                } catch (error) {
                    console.error('Erro ao obter o valor de "p" da URL:', error);
                }
            };
            confirmOrderFunc()
            setModalPrice(false)
        } else if (type === 'addStock') {
            const addStockFetch = async () => {
                try {
                    const fetchRefuse = async () => {
                        try {
                            const response = await productAddStock(id , estoque);
                            if (response.status === 200) {
                                setToggle(true)
                                setEstoque(0)
                            } else {
                                console.error('Erro ao obter os produtos:', response.statusText);
                            }
                        } catch (error) {
                            console.error('Erro ao obter os produtos:', error);
                        }
                    };
                    fetchRefuse();
                } catch (error) {
                    console.error('Erro ao obter o valor de "p" da URL:', error);
                }
            };
            addStockFetch()
            setModalStock(false)
        } else if (type === 'rmvStock') {
            const addStockFetch = async () => {
                try {
                    const fetchRefuse = async () => {
                        try {
                            const response = await productRmvStock(id , estoque);
                            if (response.status === 200) {
                                setToggle(true)
                                setEstoque(0)
                            } else {
                                console.error('Erro ao obter os produtos:', response.statusText);
                            }
                        } catch (error) {
                            console.error('Erro ao obter os produtos:', error);
                        }
                    };
                    fetchRefuse();
                } catch (error) {
                    console.error('Erro ao obter o valor de "p" da URL:', error);
                }
            };
            addStockFetch()
            setModalStock(false)
        }
    }

    const checkProduct = async () => {
        try {
            const params = new URLSearchParams(window.location.search);
            const fetchProducts = async () => {
                try {
                    const response = await productsShop(1, 220);
                    if (response.status === 200) {
                        setProducts(response.data.result)
                        setLoading(false)
                    } else {
                        console.error('Erro ao obter os produtos:', response.statusText);
                        setLoading(false)
                    }
                } catch (error) {
                    console.error('Erro ao obter os produtos:', error);
                    setLoading(false)
                }
            };
            fetchProducts();
        } catch (error) {
            console.error('Erro ao obter o valor de "p" da URL:', error);
        }
    };

    const checkProfile = async () => {
        try {
            const fetchProfile = async () => {
                try {
                    const response = await getProfile();
                    if (response.status === 200) {
                        setShopper(response.data)
                    } else {
                        console.error('Erro ao obter os produtos:', response.statusText);
                    }
                } catch (error) {
                    console.error('Erro ao obter os produtos:', error);
                }
            };
            fetchProfile();
        } catch (error) {
            console.error('Erro ao obter o valor de "p" da URL:', error);
        }
    };

    useEffect(() => {
        if (toggle) {
            checkProduct();
            setToggle(false);
            if(!shopper.name) {
                checkProfile();
            }
        }
    }, [toggle]);

    return (
        <>
            <div className="row">
                {
                    shopper !== null ?
                        <div className="col-xl-12" style={{ maxWidth: '400px', margin: '0 auto' }}>
                            <br />
                            <span className='d-flex flex-wrap justify-content-center gap-3'>
                                <span>
                                    {
                                        !shopper.images ?
                                            <span style={{ display: 'flex', margin: '0 auto', width: 50, height: 50, borderRadius: '100%', backgroundColor: '#dedede', justifyContent: 'center', alignItems: 'center' }}>
                                                <i className='bi bi-shop' style={{ fontSize: '30px' }} />
                                            </span>
                                            : null
                                    }
                                </span>
                                <span>
                                    <h3 style={{ margin: 0 }}>{shopper.name}</h3>
                                    <p style={{ margin: 0 }}><i className="bi bi-whatsapp" style={{ marginRight: 4, color: '#F54749' }}></i>{shopper.phone}</p>
                                    <p style={{ margin: 0 }}><i className="bi bi-person-badge" style={{ marginRight: 4, color: '#F54749' }}></i>{shopper.taxId}</p>
                                    <p style={{ margin: 0 }}><i className="bi bi-telephone" style={{ marginRight: 4, color: '#F54749' }}></i>{shopper.email}</p>
                                </span>
                            </span>
                        </div>
                        : null
                }
                <span
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'spaceBetween',
                        width: '100%',
                        margin: '20px 0',
                        justifyContent: 'space-around'
                    }}
                >
                    <h3>Vitrine:</h3> <hr style={{ width: 'Calc(100% - 140px)' }} /></span>
                <div className="col-xl-12" style={{ padding: 0 }}>
                    <ul className="btn-reveal-trigger row" style={{ padding: 0 }}>
                        {
                            isLargeScreen ? 
                                products.length !== 0 ?
                                    products.map((Data, Index) => (
                                        <li className="col-sm-6 col-md-4"
                                            style={{
                                                marginBottom: 6,
                                                borderRadius: 6,
                                            }} key={Index}>
                                            <div
                                                className="p-3"
                                                style={{
                                                    boxShadow: '2px 2px 2px #f1f1f1',
                                                }}>
                                                <span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                                    {
                                                        Data.images.length > 0 ?
                                                            <img className="" style={{ width: '100%' }} src={'https://core.pskeromais.com.br/' + Data.images[0].url} />
                                                            :
                                                            <span style={{
                                                                width: '100%',
                                                                height: 200,
                                                                backgroundImage: "gray",
                                                                margin: '0 auto',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
                                                            }}>
                                                                <i className='bi bi-gift' style={{ fontSize: 45, color: 'white' }} />
                                                            </span>
                                                    }
                                                    <div style={{ width: '100%' }}>                                               <br />
                                                        <h3 className="mb-0 fs--1" style={{ width: '100%', textAlign: 'center' }}>
                                                            {Data.name}
                                                        </h3>
                                                    </div>
                                                    <div style={{ display: 'flex', gap: 3, flexDirection: 'row', justifyContent: 'center' , flexWrap: 'wrap'}}>
                                                            <span>
															<label style={{ fontSize: 10 }}>
																peso
															</label>
															<span style={{ width: '100px', backgroundColor: 'rgb(241, 241, 241)', padding: 6, borderRadius: '3px', display: 'flex', justifyContent: 'center' }}>
																<span style={{ fontSize: '15px', fontWeight: 'bold' }}>{Data.weight + ' g'}</span>
															</span>
														</span>
                                                        <span>
                                                            <label style={{ fontSize: 10 }}>preço de custo</label>
                                                            <span style={{ width: '100px', backgroundColor: 'rgb(241, 241, 241)', padding: 6, borderRadius: '3px', display: 'flex', justifyContent: 'center' }}>
                                                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{Data.costPrice.toFixed(2) + ' R$'}</span>
                                                            </span>
                                                        </span>
                                                        <span>
                                                            <label style={{ fontSize: 10 }}>preço de venda</label>
                                                            <span style={{ width: '100px', backgroundColor: 'rgb(241, 241, 241)', padding: 6, borderRadius: '3px', display: 'flex', justifyContent: 'center' }}>
                                                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{Data.salePrice.toFixed(2) + ' R$'}</span>
                                                            </span>
                                                        </span>
                                                        <span>
                                                            <label style={{ fontSize: 10 }}>vitrine</label>
                                                            <span style={{ width: '100px', backgroundColor: 'rgb(241, 241, 241)', padding: 6, borderRadius: '3px', display: 'flex', justifyContent: 'center' }}>
                                                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{Data.onSalePrice.toFixed(2) + ' R$'}</span>
                                                            </span>
                                                        </span>
                                                        <span>
                                                            <label style={{ fontSize: 10 }}>estoque</label>
                                                            <span style={{ padding: '6px', borderRadius: '3px', backgroundColor: 'rgb(241, 241, 241)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{Data.stock}</span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </span>
                                                <hr />
                                                <div style={{ textAlign: 'center', width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
                                                    <button onClick={() => setModal(true, 'stock', Data)} className="btn btn-info d-flex justify-content-center align-items-center white d-flex p-1 px-2 mt-2 mr-auto ml-auto">
                                                        estoque
                                                    </button>
                                                    <button onClick={() => setModal(true, 'price', Data)} className="btn btn-secondary d-flex justify-content-center align-items-center white d-flex p-1 px-2 mt-2 mr-auto ml-auto">
                                                        preço
                                                    </button>
                                                    <a href={`/produto?p=${Data.id}`} className="btn btn-primary d-flex justify-content-center align-items-center white d-flex p-1 px-2 mt-2 mr-auto ml-auto">
                                                        produto
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                    )) : null
                            :
                                <>
                                    <h2 style={{ padding: '6px', borderRadius: '3px', backgroundColor: 'rgb(241, 241, 241)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>12 G</h2>
                                    <SwiperCTN products={products.filter(product => product.weight === 12)} stock={(data) => (console.log(data) , setModal(true, 'stock', data))} price={(data) => setModal(true, 'price', data)}/>
                                    <h2 style={{ padding: '6px', borderRadius: '3px', backgroundColor: 'rgb(241, 241, 241)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>15 G</h2>
                                    <SwiperCTN products={products.filter(product => product.weight === 15)} stock={(data) => setModal(true, 'stock', data)} price={(data) => setModal(true, 'price', data)}/>
                                    <h2 style={{ padding: '6px', borderRadius: '3px', backgroundColor: 'rgb(241, 241, 241)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>30 G</h2>
                                    <SwiperCTN products={products.filter(product => product.weight === 30)} stock={(data) => setModal(true, 'stock', data)} price={(data) => setModal(true, 'price', data)}/>
                                    <h2 style={{ padding: '6px', borderRadius: '3px', backgroundColor: 'rgb(241, 241, 241)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>35 G</h2>
                                    <SwiperCTN products={products.filter(product => product.weight === 35)} stock={(data) => setModal(true, 'stock', data)} price={(data) => setModal(true, 'price', data)}/>
                                    <h2 style={{ padding: '6px', borderRadius: '3px', backgroundColor: 'rgb(241, 241, 241)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>80 G</h2>
                                    <SwiperCTN products={products.filter(product => product.weight === 80)} stock={(data) => setModal(true, 'stock', data)} price={(data) => setModal(true, 'price', data)}/>
                                    <h2 style={{ padding: '6px', borderRadius: '3px', backgroundColor: 'rgb(241, 241, 241)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>100 G</h2>
                                    <SwiperCTN products={products.filter(product => product.weight === 100)} stock={(data) => setModal(true, 'stock', data)} price={(data) => setModal(true, 'price', data)}/>
                                </>
                        }
                    </ul>
                </div>
            </div>
            <Modal className="custom-modal fade d-flex justify-content-center " show={modalPrice}>
                <button onClick={() => setModalPrice(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex justify-content-center flex-column p-4' style={{ minHeight: 300, minWidth: 350 }}>
                    <h5 className="text-primary" style={{ borderBottom: '1px solid #f1f1f1', paddingBottom: 7, margin: 0 }}><i className="bi bi-cash text-primary" style={{ fontSize: 21 }}></i> Ajustar Preço</h5>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' , justifyContent: 'center' }}>
                        <span className="" style={{ width: 100, height: 140, overflow: 'hidden', display: 'flex', justifyContent: 'center' }}>
                            {
                                orderTaken.name ?
                                    orderTaken.images.length > 0 ?
                                        <img className="" style={{ height: '100%' }} src={'https://core.pskeromais.com.br/' + orderTaken.images[0].url} />
                                        :
                                        <span style={{
                                            width: '100%',
                                            height: 200,
                                            backgroundImage: "gray",
                                            margin: '0 auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
                                        }}>
                                            <i className='bi bi-gift' style={{ fontSize: 45, color: 'white' }} />
                                        </span> : null
                            }
                        </span>
                        <span style={{ marginTop: 16 , marginLeft: 6 }}>
                            <h5>{orderTaken.name ? orderTaken.name : null}</h5>
                            <div style={{fontSize: 10 , fontWeight: 'bold'}}>preço de custo</div>
                            <span>{orderTaken.costPrice ? <>{ orderTaken.costPrice.toFixed(2) + ' R$'}</> : ' - '}</span>
                            <div style={{fontSize: 10 , fontWeight: 'bold'}}>preço de venda</div>
                            <span>{orderTaken.salePrice ? <>{ orderTaken.salePrice.toFixed(2) + ' R$'}</> : ' - '}</span>
                            <div style={{fontSize: 10 , fontWeight: 'bold'}}>preço final</div>
                            <span>{ orderTaken.onSalePrice ?<>{ orderTaken.onSalePrice.toFixed(2) + 'R$'}</>: ' - '}</span>
                            <div style={{fontSize: 10 , fontWeight: 'bold'}}>estoque atual</div>
                            <span>{orderTaken.stock ? orderTaken.stock : ' - '}</span>
                        </span>
                    </div>
                    <br />
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 28px 0' , gap: 2 }}>
                        <label style={{ color: '#494949', fontWeight: 'bold', margin: '5px 0 0 0' }}>preço de custo</label>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <input value={precoCusto.toFixed(2)} onChange={(e) => setPrecoCusto(parseFloat(e.target.value) || 0)} className="filled-in chk-col-primary" style={{ width: '100%', border: '1px solid rgb(178 178 178)', borderRadius: 6 , height: 50 , marginRight: 3 , textAlign: 'center' }} />
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <button onClick={handleIncrement(setPrecoCusto)} className='btn btn-primary' style={{ width: 23, height: 23, display: 'flex', padding: 0, justifyContent: 'center', alignItems: 'center' }}>
                                    <i className="bi bi-caret-up-fill"></i>
                                </button>
                                <button onClick={handleDecrement(setPrecoCusto)} className='btn btn-primary' style={{ width: 23, height: 23, display: 'flex', padding: 0, justifyContent: 'center', alignItems: 'center' }}>
                                    <i className="bi bi-caret-down-fill"></i>
                                </button>
                            </div>
                        </div>
                        <label style={{ color: '#494949', fontWeight: 'bold', margin: '5px 0 0 0' }}>preço de venda</label>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <input value={precoVenda.toFixed(2)} onChange={(e) => setPrecoVenda(parseFloat(e.target.value) || 0)} className="filled-in chk-col-primary" style={{ width: '100%', border: '1px solid rgb(178 178 178)', borderRadius: 6 , height: 50 , marginRight: 3 , textAlign: 'center' }} />
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <button className='btn btn-primary' style={{ width: 23, height: 23, display: 'flex', padding: 0, justifyContent: 'center', alignItems: 'center' }} onClick={handleIncrement(setPrecoVenda)}>
                                    <i className="bi bi-caret-up-fill"></i>
                                </button>
                                <button className='btn btn-primary' style={{ width: 23, height: 23, display: 'flex', padding: 0, justifyContent: 'center', alignItems: 'center' }} onClick={handleDecrement(setPrecoVenda)}>
                                    <i className="bi bi-caret-down-fill"></i>
                                </button>
                            </div>
                        </div>
                        <label style={{ color: '#494949', fontWeight: 'bold', margin: '5px 0 0 0' }}>vitrine</label>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <input value={vitrine.toFixed(2)} onChange={(e) => setVitrine(parseFloat(e.target.value) || 0)} className="filled-in chk-col-primary" style={{ width: '100%', border: '1px solid rgb(178 178 178)', borderRadius: 6 , height: 50 , marginRight: 3 , textAlign: 'center' }} />
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <button className='btn btn-primary' style={{ width: 23, height: 23, display: 'flex', padding: 0, justifyContent: 'center', alignItems: 'center' }} onClick={handleIncrement(setVitrine)}>
                                    <i className="bi bi-caret-up-fill"></i>
                                </button>
                                <button className='btn btn-primary' style={{ width: 23, height: 23, display: 'flex', padding: 0, justifyContent: 'center', alignItems: 'center' }} onClick={handleDecrement(setVitrine)}>
                                    <i className="bi bi-caret-down-fill"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => setModalPrice(false)} className='d-flex align-items-center justify-content-center flex-row' style={{ gap: 4 }}>
                        <button onClick={() =>  handlerStore(orderTaken.id , 'price')} className='btn btn-primary'>
                            confirmar
                        </button>
                        <button className='btn btn-light'>
                            cancelar
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal className="custom-modal fade d-flex align-items-center justify-content-center " show={modalStock}>
                <button onClick={() => setModalStock(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex justify-content-center flex-column p-4' style={{ minHeight: 300 , minWidth: 350}}>
                    <h5 className="text-primary" style={{ borderBottom: '1px solid #f1f1f1', paddingBottom: 7, margin: 0 }}><i className="bi bi-stack text-primary" style={{ fontSize: 21 }}></i> Estoque:</h5>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' , justifyContent: 'center' }}>
                        <span className="" style={{ width: 100, height: 140, overflow: 'hidden', display: 'flex', justifyContent: 'center' , alignContent: 'center' }}>
                            {
                                orderTaken.name ?
                                    orderTaken.images.length > 0 ?
                                        <img className="" style={{ height: '100%' }} src={'https://core.pskeromais.com.br/' + orderTaken.images[0].url} />
                                        :
                                        <span style={{
                                            width: '100%',
                                            height: 200,
                                            backgroundImage: "gray",
                                            margin: '0 auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
                                        }}>
                                            <i className='bi bi-gift' style={{ fontSize: 45, color: 'white' }} />
                                        </span> : null
                            }
                        </span>
                        <span style={{ marginTop: 16 }}>
                            <h5>{orderTaken.name ? orderTaken.name : null}</h5>
                            <div style={{fontSize: 10 , fontWeight: 'bold'}}>preço de custo</div>
                            <span>{orderTaken.costPrice ? <>{ orderTaken.costPrice.toFixed(2) + ' R$'}</> : ' - '}</span>
                            <div style={{fontSize: 10 , fontWeight: 'bold'}}>preço de venda</div>
                            <span>{orderTaken.salePrice ? <>{ orderTaken.salePrice.toFixed(2) + ' R$'}</> : ' - '}</span>
                            <div style={{fontSize: 10 , fontWeight: 'bold'}}>vitrine</div>
                            <span>{ orderTaken.onSalePrice ?<>{ orderTaken.onSalePrice.toFixed(2) + 'R$'}</>: ' - '}</span>
                            <div style={{fontSize: 10 , fontWeight: 'bold'}}>estoque atual</div>
                            <span>{orderTaken.stock ? orderTaken.stock : ' - '}</span>
                        </span>
                    </div>
                    <br />
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 28px 0' , gap: 2 }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <input value={estoque} className="filled-in chk-col-primary" style={{ width: '100%', border: '1px solid rgb(178 178 178)', borderRadius: 6 , height: 50 , marginRight: 3 , textAlign: 'center' }} onChange={(e) => setEstoque(parseInt(e.target.value, 10) || 0)}/>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <button onClick={handleStockIncrement} className='btn btn-primary' style={{ width: 23, height: 23, display: 'flex', padding: 0, justifyContent: 'center', alignItems: 'center' }}>
                                    <i className="bi bi-caret-up-fill"></i>
                                </button>
                                <button  onClick={handleStockDecrement} className='btn btn-primary' style={{ width: 23, height: 23, display: 'flex', padding: 0, justifyContent: 'center', alignItems: 'center' }}>
                                    <i className="bi bi-caret-down-fill"></i>
                                </button>
                            </div>
                        </div>
                    </div>    
                    <div onClick={() => setModalStock(false)} className='d-flex align-items-center justify-content-center flex-row' style={{ gap: 4 }}>
                        <button onClick={() => handlerStore(orderTaken.id , 'addStock')} className='btn btn-primary'>
                            adicionar
                        </button>
                        <button onClick={() => handlerStore(orderTaken.id , 'rmvStock')} className='btn btn-secondary'>
                            remover
                        </button>
                        <button className='btn btn-light'>
                            cancelar
                        </button>
                    </div>
                </div>
            </Modal>
            <span style={{zIndex: 9999 , width: '100%' , height: '100vh' , backgroundColor: 'rgba(41 ,180,226,0.6)' , position: 'absolute' , top: 0, right: 0, display: loading?'flex':'none' , justifyContent: 'center' , alignItems: 'center'}} >
                <i id="loading-arrow-in-circle" class="bi bi-arrow-clockwise" style={{color: 'white' , fontSize: 75}}></i>
            </span>
        </>
    )
}
export default Menu; 