import React from 'react';
import { Swiper, SwiperSlide, } from "swiper/react";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper";

import "swiper/css";

const SwiperCTN = (props) => {

	const setStock = (id) => {
		console.log('--- id ----')
		console.log(id)
		props.stock(id)
	}

	const setPrice = (id) => {
		console.log('--- id ----')
		console.log(id)
		props.price(id)
	}

	return (
		<>
			<Swiper className="mySwiper-2"
				speed={3200}
				slidesPerView={5}
				spaceBetween={20}
				autoplay={{
					delay: 1200,
				}}
				modules={[Autoplay]}
				breakpoints={{
					360: {
						slidesPerView: 2,
						spaceBetween: 20,
					},
					600: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					700: {
						slidesPerView: 4,
						spaceBetween: 20,
					}
					,
					1200: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					1920: {

						slidesPerView: 5,
						spaceBetween: 20,
					},
				}}
			>
				{//props.products > 0 ? 

					props.products.map((Data, key) => {

						return (
							<SwiperSlide key={key + '-swipper'}>
								<div className="cate-bx text-center">
									<div className="card">
										<div
											className="p-1"
											style={{
												boxShadow: '2px 2px 2px #f1f1f1',
											}}>
											<span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
												{Data.images.length > 0 ?
													<img className="" style={{ width: '100%' }} src={'https://core.pskeromais.com.br/' + Data.images[0].url} />
													: <span style={{
														width: '100%',
														height: 120,
														backgroundImage: "gray",
														margin: '0 auto',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
													}}>
														<i className='bi bi-gift' style={{ fontSize: 45, color: 'white' }} />
													</span>
												}
												<div className="d-flex align-items-center" style={{width: '100%' , justifyContent: 'center'}}>
													<div>
														<br />
														<div className="d-flex" style={{justifyContent: 'center', width: '100%'}}>
															<h4 style={{textAlign: 'center' , width: '100%', color: '#036383' }}>
																{Data.name}
															</h4>
														</div>
													</div>
												</div>
												{/* <span style={{ display: 'flex', flexDirection: 'column', gap: 4 }}> */}
													<div style={{ display: 'flex', gap: 4, flexDirection: 'row', justifyContent: 'center' , flexWrap: 'wrap'}}>
														<span>
															<label style={{ fontSize: 10 }}>
																peso
															</label>
															<span style={{  backgroundColor: 'rgb(241, 241, 241)', padding: 3, borderRadius: '3px', display: 'flex', justifyContent: 'center' }}>
																<span style={{ fontSize: '15px', fontWeight: 'bold' }}>{Data.weight + ' g'}</span>
															</span>
														</span>
														<span>
                                                            <label style={{ fontSize: 10 }}>custo</label>
                                                            <span style={{  backgroundColor: 'rgb(241, 241, 241)', padding: 3, borderRadius: '3px', display: 'flex', justifyContent: 'center' }}>
                                                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{Data.costPrice.toFixed(2) + ' R$'}</span>
                                                            </span>
                                                        </span>
                                                        <span>
                                                            <label style={{ fontSize: 10 }}>venda</label>
                                                            <span style={{  backgroundColor: 'rgb(241, 241, 241)', padding: 3, borderRadius: '3px', display: 'flex', justifyContent: 'center' }}>
                                                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{Data.salePrice.toFixed(2) + ' R$'}</span>
                                                            </span>
                                                        </span>
                                                        <span>
                                                            <label style={{ fontSize: 10 }}>vitrine</label>
                                                            <span style={{  backgroundColor: 'rgb(241, 241, 241)', padding: 3, borderRadius: '3px', display: 'flex', justifyContent: 'center' }}>
                                                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{Data.onSalePrice.toFixed(2) + ' R$'}</span>
                                                            </span>
                                                        </span>
                                                        <span>
                                                            <label style={{ fontSize: 10 }}>estoque</label>
                                                            <span style={{ padding: 3, borderRadius: '3px', backgroundColor: 'rgb(241, 241, 241)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{Data.stock}</span>
                                                            </span>
                                                        </span>
                                                    </div>
													<div style={{ textAlign: 'center', width: "100%", display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
														<button onClick={() => setStock(Data)} className="btn btn-info d-flex justify-content-center align-items-center white d-flex p-1 px-2 mt-2 mr-auto ml-auto">
															estoque
														</button>
														<button onClick={() => setPrice(Data)} className="btn btn-secondary d-flex justify-content-center align-items-center white d-flex p-1 px-2 mt-2 mr-auto ml-auto">
															preço
														</button>
														<a href={`/produto?p=${Data.id}`} className="btn btn-primary d-flex justify-content-center align-items-center white d-flex p-1 px-2 mt-2 mr-auto ml-auto">
															produto
														</a>
													</div>
												{/* </span> */}
											</span>
										</div>
									</div>
								</div>
							</SwiperSlide>
						)

					})
					//: ''}
				}
				<div className="swiper-pagination"></div>
			</Swiper>
		</>
	)
}
export default SwiperCTN;